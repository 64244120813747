import React from 'react'
import styled from 'styled-components'
import { Badge } from 'reactstrap'

import { palette } from '../../lib/cssHelpers'
import CONFIG from '../../config'

interface Props {
  onClick?: (e: any) => void
  name: string
  category?: string
  glow?: boolean
}

export default function ConditionBadge(props: Props) {
  return props.name === CONFIG.HEALTHY_CONDITION_NAME ? null : (
    <Finding glow={props.glow} onClick={props.onClick} className="font-weight-bold m-0 text-dark-bg text-s d-flex align-items-center">
      {props.name}
      <Badge className="mx-1" color="dark-bg-blue">
        {props.category}
      </Badge>
    </Finding>
  )
}

interface FindingProps {
  glow?: boolean
}

const Finding = styled.p`
  width: fit-content;
  white-space: nowrap;
  background-color: #171717;
  padding: 6px 12px;
  border-radius: 20px;
  min-width: 80px;
  text-align: center;
  box-shadow: ${(p: FindingProps) => (p.glow ? `0 0 15px ${palette.secondary}` : '')};
  transition: 0.15s;
  cursor: pointer;
`
