import React from 'react'
import { useSelector } from 'react-redux'
import { QueryName } from '../../hasura/queryNames'
import { consultationsSelector, ConsultationsState } from '../../hasura/slices/consultations'

// @ts-ignore
import nextIcon from '../../lib/images/next-right-dark.png'

interface Props {
  currentIdx: number
  totalCount: number
  handleClickedPrevious: () => void
  handleClickedNext: () => void
  nextEnabled?: boolean
}

export default (props: Props) => {
  const { isQuerying }: ConsultationsState = useSelector(consultationsSelector)

  const previousEnabled = props.currentIdx > 0 && !isQuerying[QueryName.CompleteConsultation]
  const nextEnabled = props.nextEnabled || (props.currentIdx + 1 < props.totalCount && !isQuerying[QueryName.CompleteConsultation])

  return (
    <div className="d-flex align-items-center">
      <img
        className={`icon-s ${!previousEnabled ? 'pe-none opacity-50' : 'pointer'}`}
        style={{ transform: 'rotate(180deg)' }}
        src={nextIcon}
        onClick={props.handleClickedPrevious}
      />

      <p className="text-dark-bg text-s mx-2 my-0 nowrap">
        {props.currentIdx + 1} of {props.totalCount}
      </p>

      <img className={`icon-s ${!nextEnabled ? 'pe-none opacity-50' : 'pointer'}`} src={nextIcon} onClick={props.handleClickedNext} />
    </div>
  )
}
