import { Consultations_consultations } from '../hasura/graphQlQueries/types/Consultations'
import { User_users } from '../hasura/graphQlQueries/types/User'

export const getCompletedByEnterpriseId = (consultation: Consultations_consultations, user: User_users) => {
  const sourceEnterpriseId = consultation.case.dicom_server?.organization?.enterprise.id
  if (!consultation.overflow) return sourceEnterpriseId

  const userIsSourceEnterpriseVet = user.user_roles.some((r) => r.role === 'vet' && r.enterprise.id === sourceEnterpriseId)
  return userIsSourceEnterpriseVet ? sourceEnterpriseId : user.organization.enterprise.id
}
