import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'

import CONFIG from '../config'
import { OLOG, trackHotjarEvent } from '../lib/helpers'

export enum ChatGPT {
  Model3p5 = 'gpt-3.5-turbo',
  Model4 = 'gpt-4',
}

interface StringDictionary {
  [key: string]: string
}

export const findConsultationMistakesPrompt =
  "You're a veterinary radiologist tasked with identifying spelling, grammar, and logical errors in reports. Review the provided report and present the critical errors for correction, using JSON format with 'title', 'quote', and 'consideration' keys. Do not present medical errors. If there are no errors, respond with 'looks good'."

export const isObjectWithMultipleKeysAndStringValues = (obj: any): boolean =>
  isObject(obj) && Object.keys(obj).length > 1 && Object.values(obj).every((v) => typeof v === 'string')

export const queryLLM = async (content: string, model = ChatGPT.Model4): Promise<StringDictionary[]> => {
  const apiUrl = 'https://api.openai.com/v1/chat/completions'
  OLOG(`LLM: querying "${model}" and content "${content.slice(0, 1000)}"`)
  trackHotjarEvent('queried_llm')

  const result = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${CONFIG.CHAT_GPT_API_KEY}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      model,
      messages: [{ role: 'user', content }],
      temperature: 0.7,
    }),
  })

  const data = await result.json()

  try {
    const result = JSON.parse(data.choices[0].message.content)
    OLOG(`LLM: received result ${JSON.stringify(result)}`)
    const arrayResult = isArray(result) ? result : [result]
    return arrayResult.every(isObjectWithMultipleKeysAndStringValues) ? arrayResult : []
  } catch (error) {
    OLOG(`LLM: ${error}`)
    return []
  }
}
