import React, { useState, useEffect } from 'react'
import last from 'lodash/last'
import compact from 'lodash/compact'
import moment from 'moment'
import { Button, Badge, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import MedicalImageThumbnail from '../common/MedicalImageThumbnail'
import Pagination from './Pagination'
import OtherCases from './OtherCases'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'
import { QueryName } from '../../hasura/queryNames'
import { additionalImagesCount, downloadStudyParamsFor, notesWithoutCombineCompareCaseIds } from '../../lib/combineCompareHelpers'
import { getTimeSince } from '../../lib/timeHelpers'
import { pluralize, speciesFor, emojiFor, patientInfo } from '../../lib/helpers'
import { statTypeDescription } from '../../lib/pricingHelpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'

import {
  Modality,
  getConsultationTypeForModality,
  imageAndVideoCountsForUltrasound,
  isUltrasoundCase,
} from '../../lib/modalityHelpers'

import {
  ConsultationsState,
  consultationsSelector,
  downloadConsultationAttachmentsAction,
  downloadStudyAction,
} from '../../hasura/slices/consultations'

// @ts-ignore
import lock from '../../lib/images/lock.png'
// @ts-ignore
import unlock from '../../lib/images/unlock.png'
// @ts-ignore
import dots from '../../lib/images/dots.svg'

interface Props {
  consultation: Consultations_consultations
  handleSubmit: (save_work_in_progress: boolean) => void
  imageUrlsForConsultation: string[]
  inQueue: boolean
  isLocked: boolean
  lock: (_?: any) => void
  openViewer: () => void
  setConsultation: (consultation?: Consultations_consultations) => void
  timeSince?: any
}

const IN_DANGER_HOURS_LEFT = 6
const UPDATE_TIME_INTERVAL_MS = 5000

export default (props: Props) => {
  const dispatch = useDispatch()

  const { consultation, inQueue } = props
  const { patient } = consultation.case
  const organization = consultation.case.dicom_server?.organization

  const [timeSinceConsultationRequested, setTimeSinceConsultationRequested] = useState<any | undefined>()
  const [timeSinceLastImage, setTimeSinceLastImage] = useState<any | undefined>()
  const [timeSinceInterval, setTimeSinceInterval] = useState<any | undefined>()

  const { accessToken, defaultTurnaroundTime }: UsersState = useSelector(usersSelector)
  const { consultations, isQuerying }: ConsultationsState = useSelector(consultationsSelector)

  const updateTimeSince = () => {
    const consultationRequested = new Date(consultation.upgraded_at || consultation.created_at)
    const lastImage = new Date(last(consultation.case.medical_images)?.created_at)
    setTimeSinceConsultationRequested(getTimeSince(consultationRequested))
    setTimeSinceLastImage(getTimeSince(lastImage))
  }

  useEffect(() => {
    clearInterval(timeSinceInterval)
    setTimeSinceInterval(setInterval(updateTimeSince, UPDATE_TIME_INTERVAL_MS))
    updateTimeSince()
    return () => clearInterval(timeSinceInterval)
  }, [consultation])

  const maxHours = parseInt(consultation.stat_type?.hours, 10) || defaultTurnaroundTime
  const hoursElapsed = timeSinceConsultationRequested?.hours || 0
  const inDanger = consultation.stat_type?.hours === 0 || hoursElapsed > maxHours - IN_DANGER_HOURS_LEFT
  const otherCases = patient.cases.filter((c) => c.id !== consultation.case.id)

  const currentIdx = () => consultations.findIndex((c) => c.id === consultation?.id)

  const handleClickedNext = () => {
    if (props.isLocked) props.handleSubmit(true)
    props.setConsultation(nextConsultation())
  }

  const handleClickedPrevious = () => {
    if (props.isLocked) props.handleSubmit(true)
    props.setConsultation(previousConsultation())
  }

  const nextConsultation = () => {
    const nextIdx = currentIdx() + 1
    return nextIdx < consultations.length ? consultations[nextIdx] : undefined
  }

  const previousConsultation = () => (currentIdx() !== 0 ? consultations[currentIdx() - 1] : undefined)

  /*
    Viewer Methods
  */

  const downloadImages = (consultation: Consultations_consultations) =>
    dispatch(downloadStudyAction(accessToken, downloadStudyParamsFor([consultation])))

  const [imageCountStr, sendingVetNotes] = (consultation.sending_vet_notes || '').split('image(s) - ')
  const imageCount = Math.max(
    parseInt(imageCountStr, 10),
    consultation.case.medical_images.length + additionalImagesCount(consultation.sending_vet_notes)
  )

  const isXray = getConsultationTypeForModality(Modality.Xray)?.consultationTypeId === consultation.consultation_type_id

  const distributionEmailIds = (consultation.distribution_email_ids_denormalized || '').split(',').map((e) => parseInt(e))
  const distributionEmailDisplayNames = compact(
    consultation.case.dicom_server?.organization?.distribution_emails
      .filter((d) => distributionEmailIds.includes(d.id))
      .map((e) => e.display_name)
  )

  return (
    <div className="min-width-450px" style={{ marginLeft: '-8px', marginTop: '-2px' }}>
      <div>
        <div className="d-flex justify-content-between align-items-start gap-10px">
          <h3 className="text-dark-bg single-line">
            {emojiFor(speciesFor(patient.species))} {patient.display_name}
          </h3>

          {inQueue && (
            <div className="d-flex flex-grow justify-content-between">
              <img
                className="icon-m p-1 mr-3"
                role="button"
                style={{ opacity: props.isLocked ? 1 : 0.5 }}
                src={props.isLocked ? lock : unlock}
                onClick={props.lock}
              />

              <Pagination
                currentIdx={currentIdx()}
                totalCount={consultations.length}
                handleClickedPrevious={handleClickedPrevious}
                handleClickedNext={handleClickedNext}
              />
            </div>
          )}
        </div>

        <p className="text-dark-bg text-m mb-1">{patientInfo(patient)}</p>

        {inQueue ? (
          <div>
            <p className={`text-xs m-0 ${inDanger ? 'text-danger bold' : 'text-dark-bg'}`}>
              Requested{' '}
              {timeSinceConsultationRequested?.hours > 0 ? `${pluralize('hour', timeSinceConsultationRequested?.hours)} ` : ''}
              {pluralize('minute', timeSinceConsultationRequested?.minutes)} ago by{' '}
              {distributionEmailDisplayNames.length ? ` ${distributionEmailDisplayNames.slice(0, 2).join(' and ')} at` : ''}{' '}
              {organization?.display_name}
              {consultation.stat_type && ` (${statTypeDescription(defaultTurnaroundTime, consultation.stat_type)})`}
            </p>

            {consultation.case.medical_images.length > 0 && (
              <p className="text-xs m-0 text-dark-bg">
                Last image uploaded {timeSinceLastImage?.hours > 0 ? `${pluralize('hour', timeSinceLastImage?.hours)} ` : ''}
                {pluralize('minute', timeSinceLastImage?.minutes)} ago
              </p>
            )}

            {organization?.primary_phone_number && (
              <p className="text-xs m-0 text-dark-bg mt-1">
                Have questions? Call {organization?.display_name} at{' '}
                <span className="underline">{organization?.primary_phone_number}</span>.
              </p>
            )}
          </div>
        ) : (
          <p className={`text-dark-bg text-m m-0`}>
            Completed by {consultation.receiving_vet?.display_name} {moment(consultation.completed_at).fromNow()}
          </p>
        )}

        <Badge className="text-xs p-1 mt-3 mb-1" color="primary">
          {isUltrasoundCase(consultation.case) ? imageAndVideoCountsForUltrasound(consultation.case) : pluralize('image', imageCount)}
        </Badge>

        <p style={{ maxWidth: '450px' }} className="text-m m-0 text-dark-bg prewrap">
          {notesWithoutCombineCompareCaseIds(sendingVetNotes) || 'no notes'}
        </p>

        {consultation.sending_vet_private_notes && (
          <p style={{ maxWidth: '450px' }} className="text-m m-0 mt-2 text-dark-bg prewrap">
            PRIVATE NOTES: {consultation.sending_vet_private_notes}
          </p>
        )}
      </div>

      <div className="mt-3 pb-5">
        {isXray && (
          <div>
            {props.imageUrlsForConsultation.length > 0 && (
              <div style={{ gap: '20px 10px' }} onClick={props.openViewer} role="button" className="py-2 mb-4 d-flex flex-wrap">
                {props.imageUrlsForConsultation.map((src, idx) => (
                  <MedicalImageThumbnail irxMode src={src} key={idx} />
                ))}
              </div>
            )}
          </div>
        )}

        {consultation.consultation_attachments.length > 0 && (
          <div className="text-dark-bg text-s mb-3">
            <p className="m-0 bold">{pluralize('attachment', consultation.consultation_attachments.length)}</p>
            {consultation.consultation_attachments.map((a, idx) => (
              <p
                onClick={() => dispatch(downloadConsultationAttachmentsAction([a.aws_s3_url]))}
                className="m-0 underline pointer"
                key={idx}
              >
                {last(a.aws_s3_url.split(`.com/${consultation.case_id}-`))}
              </p>
            ))}
          </div>
        )}

        {consultation.case.dicom_server_study_id && (
          <Button
            onClick={() => downloadImages(consultation)}
            outline
            size="sm"
            className="irx-button flex-center"
            color="primary"
            disabled={isQuerying[QueryName.DownloadStudies]}
          >
            Download images{isQuerying[QueryName.DownloadStudies] && <Spinner className="ml-1" color="primary" size="sm" />}
          </Button>
        )}

        {consultation.case.dicom_server_study_instance_uid && (
          <Button outline size="sm" className="mt-2 mb-3 irx-button" color="primary" onClick={props.openViewer}>
            Open viewer
          </Button>
        )}

        {otherCases.length > 0 && (
          <OtherCases consultation={consultation} otherCases={otherCases} isQuerying={isQuerying[QueryName.DownloadStudies]} />
        )}
      </div>
    </div>
  )
}
