import React, { useEffect } from 'react'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import { Button, Modal, ModalBody, ModalFooter, Alert } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import CONFIG from '../../config'
import MedicalImageThumbnail from '../common/MedicalImageThumbnail'
import { ConsultationsState, consultationsSelector } from '../../hasura/slices/consultations'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'
import { commaSeparatedString, pluralize, removeSubstrings } from '../../lib/helpers'
import { isXrayCase, modalityFor } from '../../lib/modalityHelpers'
import { predictions_normalized_insert_input } from '../../../types/globalTypes'
import { slackMessageAction } from '../../hasura/slices/users'

// @ts-ignore
import closeIconWhite from '../../lib/images/close-white.png'

interface Props {
  amount?: number
  amountWithBonuses: number
  applyStatPlusBonus: boolean
  consultation: Consultations_consultations
  customFindings: predictions_normalized_insert_input[]
  handleSubmit: () => void
  imageUrlsForConsultation: string[]
  missedStatPlusBonus: boolean
  mistakes: any[]
  modal: boolean
  notes?: string
  selectedConditions: predictions_normalized_insert_input[]
  toggleModal: () => void
}

const DISABLE_CONFIRM_MS = 1250
const MAX_IMAGES = 12

export default (props: Props) => {
  const dispatch = useDispatch()

  const [disableConfirm, setDisableConfirm] = React.useState(true)

  const { conditions }: ConsultationsState = useSelector(consultationsSelector)

  const {
    applyStatPlusBonus,
    consultation,
    customFindings,
    handleSubmit,
    missedStatPlusBonus,
    amountWithBonuses,
    amount,
    selectedConditions,
    toggleModal,
  } = props

  const isXray = isXrayCase(consultation.case)

  const bonusAmount = consultation.stat_type ? CONFIG.STAT_LIGHT_ON_BONUS : CONFIG.STAT_LIGHT_ON_BONUS_NON_STAT

  const views = uniq(
    consultation.case.medical_images.map((m) =>
      removeSubstrings(m.view?.display_name || '', [' L', ' R', ' VD', 'Lateral']).toLowerCase()
    )
  )
    .sort()
    .join(' and ')

  useEffect(() => {
    setTimeout(() => setDisableConfirm(false), DISABLE_CONFIRM_MS)
  }, [])

  const selectedConditionsAndCustomFindings = selectedConditions.concat(...customFindings)

  return (
    <Modal fade={false} centered contentClassName="dark-modal min-height-500px" size="lg" zIndex={999999} isOpen toggle={toggleModal}>
      <ModalBody className="d-flex flex-column align-items-center justify-content-between">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="flex-even" />

          <p className="m-0 bold ls-sm text-l">CONFIRM</p>

          <div className="flex-even">
            <img className="pointer float-right icon-m" src={closeIconWhite} onClick={toggleModal} />
          </div>
        </div>

        <div className="p-4 my-2 d-flex flex-column align-items-center">
          <p className="text-l text-center m-0 max-width-600px">
            Before continuing, please confirm you are submitting a {modalityFor(consultation.case)} consult
            {isXray ? ` (${pluralize(`${views} image`, props.imageUrlsForConsultation.length)})` : ''} for{' '}
            {consultation.case.patient.display_name}.
          </p>

          {isXray && (
            <div className="d-flex align-items-center justify-content-center flex-wrap gap-20px mt-4 mb-2">
              {props.imageUrlsForConsultation.slice(0, MAX_IMAGES).map((src, idx) => (
                <MedicalImageThumbnail px={90} irxMode src={src} key={idx} />
              ))}
            </div>
          )}

          <div className="max-width-600px">
            {selectedConditionsAndCustomFindings.length ? (
              <p className="mb-0 mt-1 semibold text-m text-center">
                You indicated{' '}
                {commaSeparatedString(
                  compact(
                    selectedConditionsAndCustomFindings.map(
                      (c) => c.display_name || conditions.find((c2) => c2.id === c.condition_id)?.display_name
                    )
                  )
                )}
                .
              </p>
            ) : (
              <Alert color="danger" className="text-s width-fit-content mt-1">
                You indicated all imaged body systems appear normal.
              </Alert>
            )}

            {props.mistakes.length > 0 && (
              <div className="mt-3">
                {props.mistakes.slice(0, 1).map((mistake, idx) => {
                  const isTypographicalError = mistake['title'].includes('Typographical')
                  return (
                    <Alert className="min-width-400px width-fit-content" color="secondary" key={idx}>
                      {Object.keys(mistake).map((key) => {
                        const isTitle = key === 'title'
                        const isQuote = key === 'quote'
                        return (
                          <p
                            key={`${idx}-${key}`}
                            className={`m-0 ${isTitle ? 'text-xs semibold' : 'text-s'} ${isQuote ? 'font-italic my-1' : ''}`}
                          >
                            {isTitle ? <span className="bold">POTENTIAL SUGGESTION</span> : ''}
                            {isTitle ? <span className="semibold"> | </span> : ''}
                            {isQuote ? '"' : ''}
                            {mistake[key]}
                            {isQuote ? '"' : ''}
                          </p>
                        )
                      })}

                      {isTypographicalError && (
                        <div>
                          <p className="mb-0 text-s mt-3 pt-3 border-top border--secondary">
                            <span className="bold mr-1">TIP:</span>
                            <a
                              onClick={() => dispatch(slackMessageAction('ai-report', 'Specialist clicked download Grammarly link.'))}
                              target="_blank"
                              href="https://chromewebstore.google.com/detail/grammarly-ai-writing-and/kbfnbcaeplbcioakkpcpgfkobkghlhen?hl=en"
                            >
                              Grammarly
                            </a>
                            's free chrome extension can save you time checking spelling/grammar.
                          </p>
                        </div>
                      )}
                    </Alert>
                  )
                })}
              </div>
            )}
          </div>
        </div>

        <div className="d-flex flex-column align-items-center gap-20px">
          <Button
            className={`width-150px ${disableConfirm ? 'pe-none opacity-10' : 'opacity-100'} transition-s`}
            color="secondary"
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </ModalBody>

      <ModalFooter style={{ border: 'none !important' }}>
        <div className="w-100">
          <p className={`text-m m-0 ${consultation.status === 'completed' ? 'hidden' : ''}`}>
            {applyStatPlusBonus ? (
              <span>
                You are earning <span style={{ textDecoration: 'line-through' }}>${amount}</span>
                <span className="mx-1">➡️</span>
                <span className="bold text--success">${amountWithBonuses}</span> for this case.
              </span>
            ) : (
              <span>
                You are earning <span className="bold">${amountWithBonuses}</span> for this case.
              </span>
            )}

            {missedStatPlusBonus && <br />}

            {missedStatPlusBonus && (
              <span className="text-s">
                <span className="bold mr-2">TIP:</span>Earn <span className="text--success bold">${bonusAmount}</span> more (
                <span style={{ textDecoration: 'line-through' }}>${amountWithBonuses}</span>
                <span className="mx-1">➡️</span>
                <span className="text--success bold">${amountWithBonuses + bonusAmount}</span>) when your STAT+ light is on.{' '}
              </span>
            )}
          </p>
        </div>
      </ModalFooter>
    </Modal>
  )
}
