import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { Textarea } from '../../components/common/Input'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'
import ConsensusStatement, { CONSENSUS_TEXT } from './ConsensusStatement'

interface Props {
  consultation: Consultations_consultations
  addendum?: string
  setAddendum: (addendum: string) => void
  addendumPrivateText?: string
  setAddendumPrivateText: (addendumPrivateText: string) => void
}

export default function Addendums(props: Props) {
  const { consultation, addendum, setAddendum, addendumPrivateText, setAddendumPrivateText } = props
  const incompleteAddendum = consultation.addendums.find((a) => !a.text)

  const [isConsensus, setIsConsensus] = useState(false)

  useEffect(() => {
    if (!addendum?.includes(CONSENSUS_TEXT) && isConsensus) {
      setAddendum((addendum ? addendum : '') + CONSENSUS_TEXT)
    } else {
      setAddendum(addendum?.replace(CONSENSUS_TEXT, '') || '')
    }
  }, [isConsensus])

  useEffect(() => {
    setIsConsensus(false)
  }, [consultation.addendums.length])

  return (
    <div>
      {consultation.addendums
        .filter((a) => a.text)
        .map((a) => (
          <Textarea
            className="my-3"
            dark
            disabled={true}
            style={{ width: '100%', maxWidth: '900px' }}
            value={a.text || ''}
            key={a.id}
          />
        ))}

      <div className="border border--white rounded p-4 width-fit-content">
        {incompleteAddendum && (
          <div className="border-left border-secondary pl-3 mb-3">
            <p className="text-dark-bg mb-1 text-l">"{incompleteAddendum.sending_vet_text}"</p>
            <p className="text-dark-bg text-xs m-0">
              - {consultation.case.dicom_server?.organization?.display_name} asked {moment(incompleteAddendum.created_at).fromNow()}{' '}
            </p>
          </div>
        )}

        <div className="min-width-600px">
          <p className="text-dark-bg text-s bold mb-1">Addendum</p>

          <Textarea
            dark
            onChange={(e) => setAddendum(e.target.value)}
            placeholder="Addendum"
            className="w-100"
            style={{ height: '100px' }}
            value={addendum || ''}
          />
        </div>

        <ConsensusStatement isConsensus={isConsensus} setIsConsensus={setIsConsensus} />

        <div className="min-width-600px mt-3">
          <p className="text-dark-bg text-s bold mb-1">Private note (optional)</p>

          <Textarea
            dark
            onChange={(e) => setAddendumPrivateText(e.target.value)}
            placeholder="Private note"
            className="w-100"
            style={{ height: '60px' }}
            value={addendumPrivateText || ''}
          />
        </div>
      </div>
    </div>
  )
}
