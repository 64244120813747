import React from 'react'

// @ts-ignore
import ballotBox from '../../lib/images/ballot-box.svg'
// @ts-ignore
import ballotBoxChecked from '../../lib/images/ballot-box-checked.svg'

interface Props {
  isConsensus: boolean
  setIsConsensus: (isConsensus: boolean) => void
}

export const CONSENSUS_TEXT =
  '\n\nAt least one additional radiologist was consulted on this case and this report serves as a consensus opinion.'

export default (props: Props) => {
  const { isConsensus, setIsConsensus } = props
  return (
    <p className="d-flex align-items-center text-dark-bg cursor-pointer text-s mb-1" onClick={() => setIsConsensus(!isConsensus)}>
      <img className="mr-2 icon-xxxs" src={isConsensus ? ballotBoxChecked : ballotBox} />
      This is a consensus statement
    </p>
  )
}
