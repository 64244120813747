import React from 'react'

import { Textarea } from '../../components/common/Input'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'

interface Props {
  consultation?: Consultations_consultations
  followupDays?: number
  followupText?: string
  inQueue: boolean
  setFollowupDays: (days: number) => void
  setFollowupText: (followup: string) => void
}

export default function Followups(props: Props) {
  const { consultation, followupText, setFollowupText, setFollowupDays, followupDays, inQueue } = props

  return (
    <div>
      <div className="m-1">
        <span className="text-dark-bg text-m">Follow up in </span>
        <input
          className="text-dark-bg bg--black no-spinners text-m"
          style={{
            border: 'none',
            outline: 'none',
            borderBottom: '2px solid #b9b9b9',
            width: '25px',
            margin: '0 2px',
          }}
          max="10"
          min="1"
          onChange={(e: any) => setFollowupDays(e.target.value)}
          type="number"
          value={followupDays}
        />
        <span className="text-dark-bg text-m">days</span>
      </div>

      {!inQueue &&
        consultation?.followups
          .filter((f) => f.text.length > 0)
          .map((f) => (
            <Textarea
              className="my-3"
              dark
              disabled={true}
              style={{ width: '100%', maxWidth: '900px' }}
              value={f.text || ''}
              key={f.id}
            />
          ))}

      {inQueue && (
        <div className="min-width-600px">
          <Textarea
            dark
            placeholder="Notes"
            className="w-100"
            onChange={(e) => setFollowupText(e.target.value)}
            style={{ height: '100px' }}
            value={followupText || ''}
          />
        </div>
      )}
    </div>
  )
}
