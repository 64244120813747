import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import Layout from '../layouts/Layout'
import TaggingComponent from '../../components/tagging'
import { Consultations_consultations } from '../../hasura/graphQlQueries/types/Consultations'
import { fetchMedicalImageAction, unsetMedicalImagesAction } from '../../hasura/slices/medical-images'
import { noHumanPrediction } from '../../lib/helpers'
import { usersSelector, UsersState } from '../../hasura/slices/users'

interface Props {
  consultation: Consultations_consultations
  complete: () => void
  suggested: number[]
}

export default function ConsultTagging(props: Props) {
  const dispatch = useDispatch()

  const { accessToken }: UsersState = useSelector(usersSelector)

  const { consultation } = props
  const [idx, setIdx] = useState(0)

  const medicalImages = consultation.case.medical_images.filter(noHumanPrediction)

  useEffect(
    () => () => {
      dispatch(unsetMedicalImagesAction())
    },
    []
  )

  useEffect(() => {
    dispatch(fetchMedicalImageAction(accessToken!, medicalImages[idx].id))
  }, [idx])

  return (
    <Layout irxMode>
      <TaggingComponent
        complete={props.complete}
        setIdx={setIdx}
        skippable={true}
        currentIdx={idx}
        queueCount={medicalImages.length}
        suggested={props.suggested}
      />
    </Layout>
  )
}
